import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class IntegrationCard extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageWidth: PropTypes.number.isRequired,
    imageHeight: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    anchor: PropTypes.string,
    url: PropTypes.string,
    hasFee: PropTypes.bool,
    channelmanagerfee: PropTypes.bool,
  };

  render() {
    const {
      name,
      image,
      imageWidth,
      imageHeight,
      category,
      company,
      description,
      hasFee = false,
      channelmanagerfee = false,
      anchor = 'Visit Website',
      url = null,
    } = this.props;

    return (
      <div className="component integration-card">
        <div className="integration-name">{name}</div>
        <div className="integration-category">{category}</div>
        <div className="integration-image">
          <img src={image} width={imageWidth} height={imageHeight} alt={name} />
        </div>
        <div className="integration-description">{description}</div>
        {url && (
          <div className="integration-url">
            <a href={url} target="_blank" rel="noreferrer">
              {anchor}
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default IntegrationCard;
