import React, { Component } from 'react';
import IntegrationCard from '../IntegrationCard';

import './index.css';

import airbnblogo from './images/logo-airbnb.svg';
import alohaPointOfSaleLogo from './images/logo-aloha.svg';
import bookingcomlogo from './images/logo-booking.svg';
import cartStackLogo from './images/logo-cartstack.svg';
import expedialogo from './images/logo-expedia.svg';
import facebookLogo from './images/logo-facebook.svg';
import googleAnalyticsLogo from './images/logo-google-analytics.svg';
import googleHotelAdsLogo from './images/logo-google-hotel-ads.svg';
import hoteltonightlogo from './images/hotel-tonight-vector-logo.svg';
import lightspeedLogo from './images/logo-lightspeed.png';
import maitredLogo from './images/logo-maitre-d.svg';
import remoteLockLogo from './images/logo-remote-lock.svg';
import openkeyLogo from './images/logo-openkey.svg';
import revinateLogo from './images/logo-revinate.png';
import sojernLogo from './images/logo-sojern.png';
import stashRewardsLogo from './images/logo-stash-rewards.png';
import takeUpLogo from './images/logo-take-up.png';
import tripadvisorlogo from './images/logo-tripadvisor.svg';
import tripteaseLogo from './images/logo-triptease.svg';
import trustYouLogo from './images/logo-trustyou.svg';
import heartlandrestaurant from './images/logo-heartland-restaurant.svg';

export class IntegrationsPanel extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="component integrations-panel">
        <div className="integrations-panel-inner-wrapper">
          <div className="integration-cards">
            <IntegrationCard
              name="RemoteLock"
              image={remoteLockLogo}
              imageWidth={210}
              imageHeight={50}
              category="Door Locks"
              company="RemoteLock"
              description="RemoteLock integrates with industry-leading smart locks
              so businesses can automate their door locks and eliminate the hassle of
              keys for good."
              url="https://remotelock.com/"
              hasFee={true}
            />

            <IntegrationCard
              name="OpenKey"
              image={openkeyLogo}
              imageWidth={210}
              imageHeight={50}
              category="Door Locks"
              company="OpenKey"
              description="OpenKey powers digital key access for hotels worldwide. 
              Their focus is to advance your property into an exciting new era of
              guest services and customer engagement."
              url="https://www.openkey.co/"
              hasFee={true}
            />

            <IntegrationCard
              name="Google"
              image={googleHotelAdsLogo}
              imageWidth={210}
              imageHeight={50}
              category="Marketing"
              company="Google"
              description="Google Hotel Ads display your hotel availability and rates on Google Search, Maps, and the Assistant. Experience seamless connectivity, simple bidding, and measurable results. Help your next guest find you with Google Hotel Ads."
              url="https://ads.google.com/hotels/"
              hasFee={true}
            />

            <IntegrationCard
              name="TripTease"
              image={tripteaseLogo}
              imageWidth={210}
              imageHeight={16}
              category="Booking Optimization"
              company="TripTease"
              description="Identify and reach your highest-value guests first with TripTease, a platform that works across the
              booking journey — from acquisition to conversion — to make sure they book directly with your property."
              url="https://triptease.com/"
              hasFee={true}
            />

            <IntegrationCard
              name="CartStack"
              image={cartStackLogo}
              imageWidth={210}
              imageHeight={68.1}
              category="Retargeting"
              company="CartStack"
              description="CartStack recovers your lost bookings by monitoring when shoppers abandon their reservation, and sending them conversion-focused emails, SMS messages & push notifications to get them to convert."
              url="https://www.cartstack.com"
              hasFee={true}
            />

            <IntegrationCard
              name="TrustYou"
              image={trustYouLogo}
              imageWidth={210}
              imageHeight={22.8}
              category="Reputation Management"
              company="TrustYou"
              description="TrustYou provides the tools you need to make data-driven decisions, turn reviews into marketing content, and keep the conversation going with your guests so you can continuously gather feedback and improve your business."
              url="https://www.trustyou.com/"
              hasFee={true}
            />

            <IntegrationCard
              name="Stash Rewards"
              image={stashRewardsLogo}
              imageWidth={210}
              imageHeight={75}
              category="Loyalty Program"
              company="Stash Rewards"
              description="Stash Hotel Rewards provides highly-rated boutique independent hotels with all the benefits of a chain loyalty program – just without the chain."
              url="https://www.stashrewards.com/"
              hasFee={false}
            />

            <IntegrationCard
              name="TakeUp"
              image={takeUpLogo}
              imageWidth={210}
              imageHeight={76}
              category="Revenue Management"
              company="TakeUp"
              description="TakeUp is a dynamic pricing platform that leverages the power of AI and machine learning, to continuously maximize revenue and give independent properties the confidence to price courageously!"
              url="https://www.takeup.ai/"
              hasFee={false}
            />

            <IntegrationCard
              name="Revinate"
              image={revinateLogo}
              imageWidth={210}
              imageHeight={76}
              category="CRM"
              company="Revinate"
              description="Revinate's data platform and guest communication solutions put hoteliers in control of every step of their guests' journeys - from initial research to after check-out. We do all this using the communication channels the guests prefer, whether it's voice, text, email, or web chat."
              url="https://www.revinate.com/"
              hasFee={false}
            />

            <IntegrationCard
              name="Sojern"
              image={sojernLogo}
              imageWidth={210}
              imageHeight={76}
              category="Marketing"
              company="Sojern"
              description="Drive Demand. Convert Customers. Build Loyalty. Feel the power of the #1 travel marketing platform that grants you unmatched visibility into travel demand and intelligence to make the most of your budget."
              url="https://www.sojern.com/"
              hasFee={false}
            />

            <IntegrationCard
              name="Airbnb"
              image={airbnblogo}
              imageWidth={210}
              imageHeight={62}
              category="OTA"
              company="Airbnb"
              description="Gain access to a unique and loyal global audience by listing your lodging property with Airbnb. Lower your dependency on other OTAs and pause your connection at any point."
              url="https://www.airbnb.com"
              hasFee={true}
            />

            <IntegrationCard
              name="Booking.com"
              image={bookingcomlogo}
              imageWidth={210}
              imageHeight={110}
              category="OTA"
              company="Booking.com"
              description="With a mission to make it easier for everyone to experience the world, Booking.com is 
              one of the world's largest travel marketplaces. Reach an audience of millions and grow your business."
              url="https://www.booking.com"
              hasFee={true}
            />

            <IntegrationCard
              name="Expedia"
              image={expedialogo}
              imageWidth={210}
              imageHeight={200}
              category="OTA"
              company="Expedia"
              description="Listing with Expedia showcases your unique property, rooms, and features to travelers searching any of Expedia's branded sites around the world. And guests love the option to bundle lodging with flights, car rentals, and more."
              url="https://www.expedia.com"
              channelmanagerfee="true"
            />

            <IntegrationCard
              name="HotelTonight"
              image={hoteltonightlogo}
              imageWidth={220}
              imageHeight={210}
              category="OTA"
              company="HotelTonight"
              description="HotelTonight offers curated visibility, the flexibility to offer what you want when you want, and a unique focus on last-minute bookings. "
              url="https://www.hoteltonight.com"
              hasFee={true}
            />

            <IntegrationCard
              name="Tripadvisor Instant Booking"
              image={tripadvisorlogo}
              imageWidth={210}
              imageHeight={220}
              category="OTA"
              company="Tripadvisor"
              description="Tripadvisor's suite of products are designed to help you grow your lodging business. 
              Through ThinkReservations, you can take advantage of both Instant Booking and TripConnect."
              url="https://www.tripadvisor.com"
              hasFee={true}
            />

            <IntegrationCard
              name="Lightspeed"
              image={lightspeedLogo}
              imageWidth={210}
              imageHeight={62}
              category="Restaurant POS"
              company="Lightspeed"
              description="Lightspeed Restaurant is the fast, flexible multi-location platform that simplifies merchants’ processes and connects their teams, so they can focus on what matters."
              url="https://lightspeedhq.com"
              hasFee={false}
            />

            <IntegrationCard
              name="Aloha Point of Sale"
              image={alohaPointOfSaleLogo}
              imageWidth={210}
              imageHeight={62}
              category="Restaurant POS"
              company="NCS"
              description="An all-in-one restaurant technology software that will improve your guests’ experience, grow your business, allow you to serve and manage with no boundaries, and enhance your operation with robust hardware and software."
              url="https://www.ncr.com/restaurants/aloha-pos"
              hasFee={true}
            />

            <IntegrationCard
              name="Heartland Restaurant"
              image={heartlandrestaurant}
              imageWidth={210}
              imageHeight={80}
              category="Restaurant POS"
              company="Heartland"
              description="Heartland Restaurant is a powerful, cloud-based point of sale designed for fine, casual, 
              and quick service environments. The platform streamlines operations — front to back — making it easier 
              to manage and grow your omni-channel restaurant."
              url="https://www.heartlandpaymentsystems.com/restaurant/qsr"
              hasFee={true}
            />

            <IntegrationCard
              name="Maitre ‘D Point of Sale"
              image={maitredLogo}
              imageWidth={210}
              imageHeight={61.3}
              category="Restaurant POS"
              company="Posera"
              description="Maitre’d is a full service, feature-rich solution that works in all environments, such as fine dining 
              and casual dining, table service, hotels, family restaurant, and quick service. "
              url="https://www.posera.com/maitred-point-of-sale-solution/"
              hasFee={true}
            />

            <IntegrationCard
              name="Google Analytics"
              image={googleAnalyticsLogo}
              imageWidth={86.3}
              imageHeight={96}
              category="Analytics"
              company="Google"
              description="With the free tools offered by Google Analytics you’ll be able to better understand your site visitors so you can deliver better experiences using real data, not just hunches."
              url="https://marketingplatform.google.com/about/analytics/"
            />

            <IntegrationCard
              name="Facebook Pixel"
              image={facebookLogo}
              imageWidth={96}
              imageHeight={96}
              category="Analytics"
              company="Facebook"
              description="The Facebook pixel is an analytics tool that allows you to measure, optimize, and build audiences for your ad campaigns. Measure cross-device conversions, target those most likely to take action, and create dynamic ads catering to your website visitors. "
              url="https://www.facebook.com/business/learn/facebook-ads-pixel"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default IntegrationsPanel;
