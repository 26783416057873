import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Banner from '../../../core/Banner';
import IntegrationsPanel from './components/IntegrationsPanel';

import './index.css';

export class IntegrationsPage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component integrations-page">
          <Helmet title="Integrations for Hotel Software – ThinkReservations">
            <meta
              name="description"
              content="Boost your business with the help of hotel system integration services. See what ThinkReservations and our partners can provide here."
            />
            <script
              type="application/ld+json"
              children={JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Service',
                serviceType: 'Integration Solutions',
                category:
                  'Reservation Management Software for Small and Boutique Hotels',
                provider: {
                  '@type': 'Organization',
                  name: 'ThinkReservations',
                },
                areaServed: {
                  '@type': 'Country',
                  name: 'USA',
                },
              })}
            />
          </Helmet>

          <Banner
            header="Hotel System Integrations"
            subheader="Do more with ThinkReservations"
            content="ThinkReservations is proud to offer seamless hotel system integrations that connect our platform to many 
            of the most popular and beneficial tools out there today. We have you covered with everything from point-of-sale 
            systems to digital marketing, online travel agencies (OTAs), and more.

            Our hotel integrations give you the functionality and convenience you need to attract more of your ideal guests 
            while running your business more efficiently behind the scenes.

            Partnering with providers of leading hospitality software means you can get the most out of our reservation 
            system while saving time and increasing revenue! Learn how our partners can enhance your business.*"
            backgroundColor="#44b7dd"
          />

          <IntegrationsPanel />
        </div>
      </App>
    );
  }
}

export default IntegrationsPage;
