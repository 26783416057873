import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class Banner extends Component {
  static propTypes = {
    header: PropTypes.string,
    subheader: PropTypes.string,
    content: PropTypes.string,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
  };

  static defaultProps = {
    backgroundColor: '#51b0d7',
    fontColor: '#ffffff',
  };

  render() {
    const {
      header,
      subheader,
      content,
      backgroundColor,
      fontColor,
    } = this.props;

    const bannerStyles = {
      backgroundColor: backgroundColor,
      color: fontColor,
    };

    return (
      <div className="component banner" style={bannerStyles}>
        <div className="banner-inner-wrapper">
          <div className="banner-background"></div>
          <div className="banner-content">
            <h1 className="banner-content-header">{header}</h1>
            <div className="banner-content-subheader">{subheader}</div>
            <div className="banner-content-content">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
